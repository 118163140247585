.containerr {
    position: relative;
   
  }
  
  /* Make the image responsive */
  .containerr img {
    width: 100%;
    height: auto;
    padding-top: 100px;
   
  }
  
  /* Style the button and place it in the middle of the container/image */
  .containerr .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .containerr .btn:hover {
    background-color: black;
  }